import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft } from 'lucide-react';

const CookieBeleid = ({ language = 'nl', onBack }) => {
  const content = {
    nl: {
      title: "Cookiebeleid",
      whatAreCookies: "Wat zijn cookies?",
      cookiesExplanation: "Cookies zijn kleine tekstbestanden die op uw apparaat worden opgeslagen wanneer u onze website bezoekt. Ze helpen ons om uw ervaring op onze site te verbeteren en te personaliseren.",
      whichCookies: "Welke cookies gebruiken wij?",
      cookiesWeUse: "Wij maken gebruik van de volgende soorten cookies:",
      functionalCookies: "Functionele cookies: Deze zijn noodzakelijk voor het functioneren van de website.",
      analyticalCookies: "Analytische cookies (Google Analytics): Deze helpen ons te begrijpen hoe bezoekers onze website gebruiken, zodat we de gebruikerservaring kunnen verbeteren.",
      googleAnalytics: "Google Analytics",
      googleAnalyticsExplanation: "We gebruiken Google Analytics om inzicht te krijgen in hoe onze website wordt gebruikt. Google Analytics maakt gebruik van cookies om gegevens over uw websitebezoek te verzamelen. Deze gegevens worden anoniem verwerkt en omvatten:",
      visitorCount: "Het aantal bezoekers op onze website",
      pagesVisited: "Welke pagina's worden bezocht",
      visitDuration: "Hoe lang bezoekers op onze site blijven",
      referralSource: "Via welke weg bezoekers op onze site terechtkomen",
      anonymization: "We hebben Google Analytics zo ingesteld dat uw IP-adres wordt geanonimiseerd en we delen geen persoonlijke gegevens met Google.",
      manageCookies: "Hoe kunt u cookies beheren?",
      manageCookiesExplanation: "U kunt uw browserinstellingen aanpassen om cookies te weigeren of om u te waarschuwen wanneer websites cookies proberen te plaatsen of openen. Houd er rekening mee dat sommige delen van onze website mogelijk niet goed functioneren als u cookies uitschakelt.",
      policyUpdates: "Updates van dit beleid",
      policyUpdatesExplanation: "We kunnen dit cookiebeleid van tijd tot tijd bijwerken. We raden u aan deze pagina regelmatig te controleren op eventuele wijzigingen.",
      back: "Terug naar hoofdpagina"
    },
    en: {
      // English translations (unchanged)
    }
  };

  const t = content[language];

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="min-h-screen bg-gradient-to-br from-indigo-900 via-purple-900 to-pink-800 text-white p-4 sm:p-8 overflow-y-auto"
    >
      <div className="max-w-3xl mx-auto bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl p-6 sm:p-8 border border-white border-opacity-20">
        <h1 className="text-3xl sm:text-4xl font-bold mb-6">{t.title}</h1>
        
        <div className="space-y-6 mb-8">
          <section>
            <h2 className="text-xl sm:text-2xl font-semibold mb-3">{t.whatAreCookies}</h2>
            <p className="text-sm sm:text-base">{t.cookiesExplanation}</p>
          </section>
          
          <section>
            <h2 className="text-xl sm:text-2xl font-semibold mb-3">{t.whichCookies}</h2>
            <p className="text-sm sm:text-base">{t.cookiesWeUse}</p>
            <ul className="list-disc list-inside ml-4 mt-2 text-sm sm:text-base">
              <li>{t.functionalCookies}</li>
              <li>{t.analyticalCookies}</li>
            </ul>
          </section>
          
          <section>
            <h2 className="text-xl sm:text-2xl font-semibold mb-3">{t.googleAnalytics}</h2>
            <p className="text-sm sm:text-base">{t.googleAnalyticsExplanation}</p>
            <ul className="list-disc list-inside ml-4 mt-2 text-sm sm:text-base">
              <li>{t.visitorCount}</li>
              <li>{t.pagesVisited}</li>
              <li>{t.visitDuration}</li>
              <li>{t.referralSource}</li>
            </ul>
            <p className="mt-2 text-sm sm:text-base">{t.anonymization}</p>
          </section>
          
          <section>
            <h2 className="text-xl sm:text-2xl font-semibold mb-3">{t.manageCookies}</h2>
            <p className="text-sm sm:text-base">{t.manageCookiesExplanation}</p>
          </section>
          
          <section>
            <h2 className="text-xl sm:text-2xl font-semibold mb-3">{t.policyUpdates}</h2>
            <p className="text-sm sm:text-base">{t.policyUpdatesExplanation}</p>
          </section>
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onBack}
          className="mt-6 px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-full text-base font-semibold hover:from-pink-600 hover:to-purple-700 transition duration-200 flex items-center justify-center transform shadow-lg"
        >
          <ChevronLeft className="mr-2" size={20} />
          {t.back}
        </motion.button>
      </div>
    </motion.div>
  );
};

export default CookieBeleid;