import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, X } from 'lucide-react';

const IntroScreen = ({ onClose, language }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: language === 'nl' ? 'Happy Breather' : 'Happy Breather',
      content: language === 'nl' 
        ? 'Ontdek de kracht van ademhaling met Happy Breather. Deze app helpt je om bewuster te ademen en zo je gezondheid en welzijn te verbeteren.'
        : 'Discover the power of breathing with Happy Breather. This app helps you breathe more consciously to improve your health and well-being.'
    },
    {
      title: language === 'nl' ? 'Rondes' : 'Rounds',
      content: language === 'nl'
        ? 'Kies uit twee soorten ademhalingsoefeningen. Bij Rondes wissel je 1 tot 10 keer tussen ademen en de adem vasthouden.'
        : 'Choose from two types of breathing exercises. In Rounds, you alternate between breathing and holding your breath 1 to 10 times.'
    },
    {
      title: language === 'nl' ? 'Ademhalingen' : 'Breaths',
      content: language === 'nl'
        ? 'Stel bij Ademhalingen in hoeveel keer je wilt ademen. Pas ook de duur van in- en uitademen aan naar wens.'
        : 'In Breaths, set your desired number of breaths. Adjust the duration of inhaling and exhaling as you prefer.'
    },
    {
      title: language === 'nl' ? 'Aan de slag' : 'Get Started',
      content: language === 'nl'
        ? 'Start je ademhalingsoefening en ervaar hoe bewust ademen je leven kan veranderen. Adem diep in, laat los, en voel het verschil.'
        : 'Begin your breathing exercise and experience how conscious breathing can transform your life. Breathe in deeply, let go, and feel the difference.'
    },
    {
      title: language === 'nl' ? 'Veiligheid eerst' : 'Safety first',
      content: language === 'nl'
        ? 'Voor je veiligheid: Doe deze ademhalingsoefeningen niet in of onder water, of tijdens het besturen van een voertuig. Voorkom ongelukken door altijd in een veilige omgeving te oefenen.'
        : 'For your safety: Do not perform these breathing exercises in or under water, or while operating a vehicle. Prevent accidents by always practicing in a safe environment.'
    }
  ];

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-gradient-to-br from-indigo-900 via-purple-900 to-pink-800 flex items-center justify-center p-4 z-50">
      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl p-6 sm:p-8 max-w-lg w-full border border-white border-opacity-20"
      >
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-white hover:text-pink-300 transition-colors duration-300"
        >
          <X size={24} />
        </button>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="text-center mb-6"
          >
            <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4 tracking-wide">{slides[currentSlide].title}</h2>
            <div className="h-48 overflow-y-auto px-4 sm:px-6 scrollbar-thin scrollbar-thumb-pink-400 scrollbar-track-transparent">
              <p className="text-lg sm:text-xl text-gray-100 text-justify hyphens-auto leading-relaxed">
                {slides[currentSlide].content}
              </p>
            </div>
          </motion.div>
        </AnimatePresence>
        <div className="flex justify-between items-center mt-4">
          <div className="flex space-x-2">
            {slides.map((_, index) => (
              <motion.div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentSlide ? 'bg-pink-400' : 'bg-gray-400'
                }`}
                initial={false}
                animate={{ scale: index === currentSlide ? 1.2 : 1 }}
                transition={{ duration: 0.3 }}
              />
            ))}
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={nextSlide}
            className="px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-full transition-colors duration-300 flex items-center text-lg font-semibold shadow-lg"
          >
            {currentSlide < slides.length - 1 ? (language === 'nl' ? 'Volgende' : 'Next') : (language === 'nl' ? 'Start' : 'Start')}
            <ChevronRight className="ml-2" size={20} />
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default IntroScreen;